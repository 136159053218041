$(document).ready(function () {
	var urlParams = new URLSearchParams(window.location.search);
	var emailParam = urlParams.get('email');
	if (emailParam) {
		$('html, body').animate(
			{
				scrollTop: $('#intelihubform').offset().top,
			},
			100
		);

		$('#intelihubform').replaceWith(
			'<div class="submitted-message"><p>Form submitted successfully, we will reach out soon.</p></div>'
		);
	}
});
